/**
 * HubbData - Data Synchronization and Management System
 *
 * Overview:
 * This module manages the synchronization of application data between the client and server,
 * handling multiple languages and various network conditions. It implements a robust sync
 * strategy with the following key features:
 *
 * 1. Initial Load:
 *    - Loads data from localStorage if available
 *    - Performs a full update if data is from a previous month
 *    - Language data is loaded dynamically only when needed
 *
 * 2. Sync Strategies:
 *    a) Full Update:
 *       - Downloads complete dataset from server
 *       - Used for initial load and language changes
 *       - Falls back to dynamic local language data if offline
 *       - Updates localStorage with new data
 *
 *    b) Partial Update:
 *       - Fetches only changes since last update using cutoff timestamp
 *       - Merges new data with existing data
 *       - More efficient for regular polling updates
 *
 * 3. Error Handling:
 *    - Graceful offline fallback to localStorage
 *    - Timeout handling for slow connections
 *    - Automatic retry mechanism for failed requests
 *
 * 4. Background Sync:
 *    - Automatic polling every minute for updates
 *    - Uses partial updates to minimize data transfer
 *    - Can be manually started/stopped
 *
 * Usage:
 * The store automatically initializes and starts polling on creation.
 * Manual sync can be triggered using store.dispatch('fullUpdate')
 * Language can be changed using store.dispatch('updateLang', langCode)
 */

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

// Global polling interval (1 minute)
const POLLING_INTERVAL = 1 * 60 * 1000;

// API Configuration
const API_CONFIG = {
  // Live site - WON'T WORK UNTIL RAILS 8 GOES LIVE DUE TO CACHING CHANGES
  baseURL: "https://southgloucestershire2.careleaversapp.thisisfocus.co.uk/api",

  // Rails 8 Live Testing Site - should work right now
  // baseURL: "https://southgloucestershire2.careleaversapp.thisisfocus.co.uk/api",

  // Local testing branch, if you want to test data locally
  // baseURL: 'https://cla_ealing.hubbnext.thisisfocus.co.uk/api',
  auth: {
    username: "appfeed",
    password: "D$TQ9Hb7AvTrwkhx",
  },
  endpoints: {
    appFeed: "app_feed",
  },
  timeouts: {
    full: 10000, // 10 seconds
    partial: 5000, // 5 seconds
  },
};

// Debug flag
const DEBUG = true;

// Debug logging helper
function debugLog(...args) {
  if (DEBUG) {
    console.log("[HUBBDATA DEBUG]", ...args);
  }
}

// Helper functions
/**
 * Builds the URL for the app feed API
 * @param {string} lang - Language code
 * @param {Object} options - URL options
 * @param {boolean} options.historic - Whether to get historic data
 * @param {number} options.cutoff - Last update timestamp to get changes since
 * @returns {string} The complete URL
 */
function buildAppFeedURL(lang, options = {}) {
  const params = new URLSearchParams();
  if (options.historic) params.append("historic", "true");
  if (options.cutoff) params.append("cutoff", options.cutoff);

  const baseURL = `${API_CONFIG.endpoints.appFeed}/${lang}.json`;
  const queryString = params.toString();
  return queryString ? `${baseURL}?${queryString}` : baseURL;
}

/**
 * Get the current timestamp
 * Single source of truth for current time across the application
 * @returns {string} ISO timestamp
 */
const getCurrentTimestamp = () => new Date().toISOString();

/**
 * Check if a timestamp is from the current month
 * @param {number} timestamp - Unix timestamp in seconds
 * @returns {boolean} True if timestamp is from current month
 */
function isCurrentMonth(timestamp) {
  if (!timestamp) return false;

  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const now = new Date();

  return (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth()
  );
}

// Configure axios instance with default auth and error handling
const axiosHub = axios.create({
  baseURL: API_CONFIG.baseURL,
  auth: API_CONFIG.auth,
});

// Add response interceptor for consistent error handling
axiosHub.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = error.response?.data?.message || error.message;
    console.error(`API Error: ${errorMessage}`);
    // Enhance error with custom properties
    error.isOffline = !navigator.onLine;
    error.isTimeout = error.code === "ECONNABORTED";
    throw error;
  }
);

// Dynamic language data loader
/**
 * Dynamically imports language data for the specified language
 * @param {string} lang - Language code to load data for
 * @returns {Promise<Object>} The imported language data
 * @throws {Error} If language is unsupported or import fails
 */
const loadLanguageData = async (lang) => {
  if (!SUPPORTED_LANGUAGES.includes(lang)) {
    throw new Error(`Unsupported language: ${lang}`);
  }
  try {
    return await import(`../assets/data/${lang}.json`);
  } catch (error) {
    console.error(`Failed to load language data for ${lang}:`, error);
    throw error;
  }
};

// List of supported language codes
const SUPPORTED_LANGUAGES = ["en", "ar", "ckb", "pl", "pt-PT", "ps", "vi"];

Vue.use(Vuex);

// Create store
const hubbdata = {
  namespaced: true,
  state: {
    last_fetch: null,
    last_update: null,
    config: {},
    app_blocks: [],
    cms_pages: [],
    events: [],
    organisations: [],
    categories: [],
    links: [],
    opportunities: [],
    surveys: [],
    survey_questions: [],
    documents: [],
    offers: [],
    connected: navigator.onLine,
    current_lang: localStorage.getItem("current_lang"),
    last_remote_update: undefined,
    current_cms_page: "",
    openDyslexic:
      localStorage.getItem("enable_font_change") === "true" || false,
    languages: [
      { name: "English", code: "en" },
      { name: "Arabic | عربي", code: "ar" },
      { name: "Kurdish (Sorani) | کوردی", code: "ckb" },
      { name: "Polish | Polski", code: "pl" },
      { name: "Portuguese | Português", code: "pt-PT" },
      { name: "Pashto | پښتو", code: "ps" },
      { name: "Vietnamese | Tiếng Việt", code: "vi" },
    ],
    sync_status: {
      loading: false,
      error: null,
      last_attempt: null,
    },
    polling_timer: null,
    settings: {
      title: "Settings",
      text: {
        changedefault: "Change Default Font",
        languages: "Change Language",
        close: "Close",
        textopen: "OpenDyslexic font enabled",
        textdefault: "Default font enabled",
        languageset: "Language changed to",
      },
    },
    networkAlert: false,
    news: [],
  },
  getters: {
    translations: (state) => state.config.translations,
    contact: (state) => state.config.contact,
    openDyslexic: (state) => state.openDyslexic,
    getLanguages: (state) => state.languages,
    rootCmsPages: (state) =>
      state.cms_pages.filter((cms_page) => cms_page.parent_id == "cms_page_"),
    getCmsPages: (state) => state.cms_pages,
    getCmsPageById: (state) => (id) => {
      var page = state.cms_pages.find((cms_page) => cms_page.id === id);
      state.current_cms_page = page.page_title;
      return page;
    },
    getCmsPageByPath: (state) => (path) => {
      var page = state.cms_pages.find((cms_page) => cms_page.path === path);
      state.current_cms_page = page.page_title;
      return page;
    },
    getCmsPageByCategory: (state) => (category_id) => {
      return state.cms_pages.find(
        (cms_page) => cms_page.category_ids.indexOf(category_id) > -1
      );
    },
    getDocumentsByCategory: (state) => (category_id) => {
      return state.documents.filter(
        (documents) => documents.category_ids.indexOf(category_id) > -1
      );
    },
    getLinksByCategory: (state) => (category_id) => {
      return state.links.filter(
        (links) => links.category_ids.indexOf(category_id) > -1
      );
    },
    getOrganisations: (state) => state.organisations,
    getOrganisationById: (state) => (organisations_id) => {
      return state.organisations.find(
        (organisations) => organisations.id === organisations_id
      );
    },
    getOrganisationsByCategory: (state) => (category_id) => {
      return state.organisations.filter(
        (organisations) => organisations.category_ids.indexOf(category_id) > -1
      );
    },
    getOpportunities: (state) => state.opportunities,
    getOpportunityById: (state) => (opportunity_id) => {
      return state.opportunities.find(
        (opportunity) => opportunity.id === opportunity_id
      );
    },
    getEvents: (state) => state.events,
    getEventById: (state) => (events_id) => {
      return state.events.find((events) => events.id === events_id);
    },
    getCategoryPageById: (state) => (category_id) => {
      return state.categories.find(
        (categories) => categories.id === category_id
      );
    },
    getCategoriesPageByCategory: (state) => (category_id) => {
      let id = category_id.replace(/^\D+/g, "");
      return state.categories.filter(
        (categories) => categories.ancestry === id
      );
    },
    getCmsPagesByParentId: (state) => (parent_id) => {
      return state.cms_pages.filter(
        (cms_page) => cms_page.parent_id == parent_id
      );
    },
    getRootCategories: (state) => {
      return state.categories.filter(
        (categories) => categories.ancestry == null
      );
    },
    getAppBlocks: (state) => {
      return state.app_blocks;
    },
    getOffers: (state) => state.offers,
    getOfferById: (state) => (offerId) => {
      return state.offers.find((offer) => offer.id === offerId);
    },
    getNetworkAlert(state) {
      return state.networkAlert;
    },
    getPages: (state) => {
      return state.cms_pages;
    },
    getNews: (state) => state.news,
    getNewsArticleById: (state) => (id) => {
      return state.news.find((article) => article.id === id);
    },
  },
  mutations: {
    setLastUpdate(state, last_update) {
      state.last_update = last_update;
    },
    setLastFetch(state, last_fetch) {
      state.last_fetch = last_fetch;
    },
    setConfig(state, config) {
      state.config = config;
    },
    // Replace mutations (for full updates)
    setAppBlocks(state, app_blocks) {
      state.app_blocks = app_blocks;
    },
    setCmsPages(state, cms_pages) {
      state.cms_pages = cms_pages;
    },
    setOrganisations(state, organisations) {
      state.organisations = organisations;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setLinks(state, links) {
      state.links = links;
    },
    setOpportunities(state, opportunities) {
      state.opportunities = opportunities;
    },
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setSurveyQuestions(state, survey_questions) {
      state.survey_questions = survey_questions;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setOffers(state, offers) {
      state.offers = offers;
    },
    // Merge mutations (for partial updates)
    mergeAppBlocks(state, app_blocks) {
      const merged = new Map([
        ...state.app_blocks.map((b) => [b.id, b]),
        ...app_blocks.map((b) => [b.id, b]),
      ]);
      state.app_blocks = Array.from(merged.values());
    },
    mergeCmsPages(state, cms_pages) {
      const merged = new Map([
        ...state.cms_pages.map((p) => [p.id, p]),
        ...cms_pages.map((p) => [p.id, p]),
      ]);
      state.cms_pages = Array.from(merged.values());
    },
    mergeOrganisations(state, organisations) {
      const merged = new Map([
        ...state.organisations.map((o) => [o.id, o]),
        ...organisations.map((o) => [o.id, o]),
      ]);
      state.organisations = Array.from(merged.values());
    },
    mergeCategories(state, categories) {
      const merged = new Map([
        ...state.categories.map((c) => [c.id, c]),
        ...categories.map((c) => [c.id, c]),
      ]);
      state.categories = Array.from(merged.values());
    },
    mergeLinks(state, links) {
      const merged = new Map([
        ...state.links.map((l) => [l.id, l]),
        ...links.map((l) => [l.id, l]),
      ]);
      state.links = Array.from(merged.values());
    },
    mergeOpportunities(state, opportunities) {
      const merged = new Map([
        ...state.opportunities.map((o) => [o.id, o]),
        ...opportunities.map((o) => [o.id, o]),
      ]);
      state.opportunities = Array.from(merged.values());
    },
    mergeSurveys(state, surveys) {
      const merged = new Map([
        ...state.surveys.map((s) => [s.id, s]),
        ...surveys.map((s) => [s.id, s]),
      ]);
      state.surveys = Array.from(merged.values());
    },
    mergeSurveyQuestions(state, questions) {
      const merged = new Map([
        ...state.survey_questions.map((q) => [q.id, q]),
        ...questions.map((q) => [q.id, q]),
      ]);
      state.survey_questions = Array.from(merged.values());
    },
    mergeDocuments(state, documents) {
      const merged = new Map([
        ...state.documents.map((d) => [d.id, d]),
        ...documents.map((d) => [d.id, d]),
      ]);
      state.documents = Array.from(merged.values());
    },
    mergeEvents(state, events) {
      const merged = new Map([
        ...state.events.map((e) => [e.id, e]),
        ...events.map((e) => [e.id, e]),
      ]);
      state.events = Array.from(merged.values());
    },
    mergeOffers(state, offers) {
      const merged = new Map([
        ...state.offers.map((o) => [o.id, o]),
        ...offers.map((o) => [o.id, o]),
      ]);
      state.offers = Array.from(merged.values());
    },
    mergeNews(state, news) {
      const merged = new Map([
        ...state.news.map((o) => [o.id, o]),
        ...news.map((o) => [o.id, o]),
      ]);
      state.news = Array.from(merged.values());
    },
    setLang(state, selectedLang) {
      // Update current_lang in state
      state.current_lang = selectedLang;

      // Set html language attribute
      const html = document.documentElement;
      html.setAttribute("lang", selectedLang);

      // Clear any cached translations to force a fresh load
      state.config = { ...state.config, translations: {} };
    },
    setFont(state, font) {
      state.openDyslexic = font;
    },
    setOpenDyslexic(state, enabled) {
      state.openDyslexic = enabled;
    },
    addAnswer(state, answer) {
      if (!state.survey_questions[answer.survey_id]) {
        state.survey_questions[answer.survey_id] = {};
      }
      state.survey_questions[answer.survey_id][answer.question_id] =
        answer.answer;
    },
    setSyncStatus(state, status) {
      state.sync_status = status;
    },
    setPollingTimer(state, timer) {
      state.polling_timer = timer;
    },
    setCurrentLang(state, lang) {
      state.current_lang = lang;
    },
    setNetworkAlert(state, networkStatusBool) {
      state.networkAlert = networkStatusBool;
    },
    setNews(state, news) {
      state.news = news;
    },
  },
  actions: {
    /**
     * Updates the current language and triggers a full data update
     * @param {Object} context - Vuex action context
     * @param {Function} context.commit - Vuex commit method
     * @param {Function} context.dispatch - Vuex dispatch method
     * @param {string} selectedLang - The language code to switch to
     * @returns {Promise<void>}
     */
    async updateLang({ commit, dispatch, state }, selectedLang) {
      if (!SUPPORTED_LANGUAGES.includes(selectedLang)) return;

      try {
        // Set the language in localStorage first
        localStorage.setItem("current_lang", selectedLang);

        // Update the language in state
        commit("setLang", selectedLang);

        // Clear existing translations before fetching new ones
        commit("setConfig", { ...state.config, translations: {} });

        // Trigger a full update to get new translations
        await dispatch("fullUpdate");

        return true;
      } catch (error) {
        console.error("Error updating language:", error);
        return false;
      }
    },
    async setFont({ commit }, font) {
      commit("setFont", font);
    },
    async updateFont({ commit }, font) {
      commit("setFont", font);
    },
    async submitAnswers(_, data) {
      return await axiosHub.post("api/survey_answers", data);
    },

    /**
     * Initializes the database
     * Loads data from localStorage if available
     * Performs full update if data is from a previous month
     *
     * @param {Object} context - Vuex action context
     * @param {Function} context.dispatch - Vuex dispatch method
     * @param {Function} context.commit - Vuex commit method
     * @param {Object} context.state - Vuex state
     * @throws {Error} If language is unsupported
     */
    async initDB({ dispatch, commit, state }) {
      try {
        debugLog("Initializing database");

        // Validate language
        if (!SUPPORTED_LANGUAGES.includes(state.current_lang)) {
          const error = `Unsupported language: ${state.current_lang}`;
          debugLog(error);
          throw new Error(error);
        }

        // Check if we already have data in Vuex store
        const hasData =
          state.app_blocks.length > 0 ||
          state.cms_pages.length > 0 ||
          state.opportunities.length > 0;

        if (!hasData) {
          // Try to load from localStorage if store is empty
          const storedData = localStorage.getItem("hubbAppData");
          if (storedData) {
            debugLog("Loading data from localStorage");
            const data = JSON.parse(storedData);
            await dispatch("loadDB", { data, merge: false });
            commit("setLastUpdate", data.last_update);
            debugLog("Successfully loaded data from localStorage");
          } else {
            debugLog("No data in localStorage, performing full update");
            return dispatch("fullUpdate");
          }
        } else {
          debugLog("Data already in Vuex store, skipping localStorage load");
        }

        // Check if we need a full update
        const needsFullUpdate =
          !state.last_update || !isCurrentMonth(state.last_update);
        if (needsFullUpdate) {
          debugLog("Data is from previous month, performing full update");
          return dispatch("fullUpdate");
        }

        // Data is current, just get recent changes
        debugLog("Data is current, performing partial update");
        return dispatch("partialUpdate");
      } catch (error) {
        console.error("Error initializing database:", error);
        throw error;
      }
    },

    /**
     * Restores data from localStorage if available
     * @param {Object} context - Vuex action context
     * @param {Function} context.dispatch - Vuex dispatch method
     * @param {Function} context.commit - Vuex commit method
     */
    async restoreFromLocalStorage({ dispatch, commit }) {
      try {
        const storedData = localStorage.getItem("hubbAppData");
        if (storedData) {
          const data = JSON.parse(storedData);
          await dispatch("loadDB", { data, merge: false });
          commit("setLastUpdate", data.last_update);
        }
      } catch (error) {
        console.error("Error restoring from localStorage:", error);
      }
    },

    /**
     * Performs a full update of the database
     * Downloads and replaces all data
     * Falls back to dynamically loading local language data if offline
     *
     * @param {Object} context - Vuex action context
     * @param {Function} context.dispatch - Vuex dispatch method
     * @param {Object} context.state - Vuex state
     */
    async fullUpdate({ dispatch, commit, state }) {
      try {
        // Skip if no language selected
        if (!state.current_lang) {
          debugLog("No language selected, skipping full update");
          return;
        }

        debugLog("Starting full update");
        const fetchStart = performance.now();

        // Get all historic data from API
        const response = await axiosHub.get(
          buildAppFeedURL(state.current_lang, { historic: true }),
          { timeout: API_CONFIG.timeouts.full }
        );
        const fetchEnd = performance.now();
        debugLog(`Server fetch took ${(fetchEnd - fetchStart).toFixed(2)}ms`);

        if (response.data) {
          commit("setNetworkAlert", false);

          const processStart = performance.now();
          await dispatch("loadDB", { data: response.data, merge: false });
          const processEnd = performance.now();
          debugLog(
            `Data processing took ${(processEnd - processStart).toFixed(2)}ms`
          );
          debugLog(
            `Total full update time: ${(processEnd - fetchStart).toFixed(2)}ms`
          );
          return dispatch("partialUpdate");
        }
      } catch (error) {
        commit("setNetworkAlert", true);
        debugLog("Error in full update:", error.message);

        if (error.response) {
          debugLog(
            "Server response:",
            error.response.status,
            error.response.statusText
          );
        }

        // Fall back to dynamically loading local language data if available
        try {
          const localStart = performance.now();
          const localData = await loadLanguageData(state.current_lang);
          if (localData) {
            await dispatch("loadDB", { data: localData.default, merge: false });
            const localEnd = performance.now();
            debugLog(
              `Local data fallback took ${(localEnd - localStart).toFixed(2)}ms`
            );
            console.warn(
              "Using offline data - some information may be out of date"
            );
          }
        } catch (localError) {
          console.error("Failed to load local data:", localError);
          throw error; // Re-throw the original error
        }
      }
    },

    /**
     * Updates timestamps and saves current state to localStorage
     * @param {Object} context - Vuex action context
     * @param {Function} context.commit - Vuex commit method
     * @param {Object} context.state - Vuex state
     */
    async updateStateAndStorage({ state }) {
      localStorage.setItem("hubbAppData", JSON.stringify(state));
    },

    /**
     * Performs a partial update of the database
     * Fetches only changes since our last update
     * Merges new data with existing data
     *
     * @param {Object} context - Vuex action context
     * @param {Function} context.dispatch - Vuex dispatch method
     * @param {Object} context.state - Vuex state
     */
    async partialUpdate({ dispatch, state }) {
      try {
        // Skip if no language selected
        if (!state.current_lang) {
          debugLog("No language selected, skipping partial update");
          return;
        }

        // Get changes since our last update
        const cutoff = state.last_update || null;
        debugLog("Starting partial update with cutoff:", cutoff);

        // Time the server request
        const fetchStart = performance.now();
        const response = await axiosHub.get(
          buildAppFeedURL(state.current_lang, { cutoff }),
          { timeout: API_CONFIG.timeouts.partial }
        );
        const fetchEnd = performance.now();
        debugLog(`Server fetch took ${(fetchEnd - fetchStart).toFixed(2)}ms`);

        // Time the data processing
        const processStart = performance.now();
        await dispatch("loadDB", { data: response.data, merge: true });
        await dispatch("updateStateAndStorage");
        const processEnd = performance.now();
        debugLog(
          `Data processing took ${(processEnd - processStart).toFixed(2)}ms`
        );
        debugLog(
          `Total update time: ${(processEnd - fetchStart).toFixed(2)}ms`
        );
      } catch (error) {
        console.error("Error in partial update:", error);
        // Don't throw - we want to continue polling even if one update fails
      }
    },

    /**
     * Loads data into the Vuex store
     * Used by both full and partial updates
     * For partial updates (merge=true):
     * 1. Merges new records with existing ones
     * 2. Filters out records not in ids array if provided
     * For full updates (merge=false):
     * - Replaces all existing data
     *
     * @param {Object} context - Vuex action context
     * @param {Function} context.commit - Vuex commit method
     * @param {Object} context.state - Vuex state
     * @param {Object} payload - Action payload
     * @param {Object} payload.data - Data to load into store
     * @param {boolean} payload.merge - If true, merge with existing data; if false, replace existing data
     */
    async loadDB({ commit, state }, { data, merge = false }) {
      if (!data) return;

      const startTime = performance.now();

      // Update last_update timestamp from response
      if (data.last_update) {
        commit("setLastUpdate", data.last_update);
        commit("setLastFetch", getCurrentTimestamp());
      }

      // Helper to safely extract data, handling both direct values and {data: [...]} structure
      const extractData = (obj) => {
        if (!obj) return [];
        return obj.data || obj || [];
      };

      // Helper to filter out records not in ids array
      const filterByIds = (records, ids) => {
        if (!ids || !Array.isArray(ids)) return records;
        return records.filter((record) => ids.includes(record.id));
      };

      if (data.config) commit("setConfig", data.config);

      // Log request type and cutoff if debugging
      debugLog(
        `Performing ${merge ? "PARTIAL" : "FULL"} update`,
        merge ? `with cutoff: ${data.last_update}` : ""
      );

      // Define data types and their mutations
      const dataTypes = {
        app_blocks: { merge: "mergeAppBlocks", set: "setAppBlocks" },
        cms_pages: { merge: "mergeCmsPages", set: "setCmsPages" },
        events: { merge: "mergeEvents", set: "setEvents" },
        organisations: { merge: "mergeOrganisations", set: "setOrganisations" },
        categories: { merge: "mergeCategories", set: "setCategories" },
        links: { merge: "mergeLinks", set: "setLinks" },
        opportunities: { merge: "mergeOpportunities", set: "setOpportunities" },
        surveys: { merge: "mergeSurveys", set: "setSurveys" },
        survey_questions: {
          merge: "mergeSurveyQuestions",
          set: "setSurveyQuestions",
        },
        documents: { merge: "mergeDocuments", set: "setDocuments" },
        offers: { merge: "mergeOffers", set: "setOffers" },
        blog_articles: { merge: "mergeNews", set: "setNews" },
      };

      // Process each data type
      Object.entries(dataTypes).forEach(([key, mutations]) => {
        if (data[key]) {
          const records = extractData(data[key]);
          debugLog(`Incoming ${key}:`, records.length);

          if (merge) {
            // For partial updates:
            // 1. Merge new records with existing ones
            commit(mutations.merge, records);
            debugLog(`After merge ${key}:`, state[key].length);

            // 2. Then filter out any records not in the ids array
            const validIds = data[key].ids;
            if (validIds && Array.isArray(validIds)) {
              const currentRecords = state[key];
              const filteredRecords = filterByIds(currentRecords, validIds);
              commit(mutations.set, filteredRecords);
              debugLog(`After deletion ${key}:`, filteredRecords.length);
            }
          } else {
            // For full updates, just set the records
            commit(mutations.set, records);
            debugLog(`After full update ${key}:`, records.length);
          }
        }
      });

      debugLog(
        `Total loadDB processing time: ${(
          performance.now() - startTime
        ).toFixed(2)}ms`
      );
    },

    /**
     * Starts polling for updates
     * Performs partial updates at regular intervals
     *
     * @param {Object} context - Vuex action context
     * @param {Function} context.dispatch - Vuex dispatch method
     */
    async startPolling({ dispatch }) {
      debugLog("Starting polling for updates");

      // Initial update
      await dispatch("initDB");

      // Set up polling interval
      setInterval(async () => {
        debugLog("Polling: checking for updates");
        await dispatch("partialUpdate");
      }, POLLING_INTERVAL);
    },

    /**
     * Stops the global polling mechanism
     *
     * @param {Object} context - Vuex action context
     * @param {Object} context.state - Vuex state
     * @param {Function} context.commit - Vuex commit method
     */
    stopGlobalSync({ state, commit }) {
      if (state.polling_timer) {
        clearInterval(state.polling_timer);
        commit("setPollingTimer", null);
      }
    },
  },
};

export default hubbdata;
