const vips = {
  namespaced: true,
  state: {
    vips: [],
  },
  getters: {
    getVips: (state) => state.vips,
    vipsCount: (state) => () => {
      return state.vips.length;
    },
  },
  mutations: {
    setVips(state, vips) {
      state.vips = vips;
    },
  },
  actions: {
    removevip({ commit, state }, vip) {
      let vips = state.vips;
      let idx = vips.findIndex((f) => f == vip);

      if (idx >= 0) {
        vips.splice(idx, 1);

        commit("setVips", vips);
      }
    },
    editvip({ commit, state }, vip) {
      let vips = state.vips;
      let idx = vips.findIndex((f) => f == vip);

      if (idx >= 0) {
        vips.splice(idx, 1, vip);

        commit("setVips", vips);
      }
    },
    addvip({ commit, state }, vip) {
      let vips = state.vips;
      vips.push(vip);

      commit("setVips", vips);
    },
  },
};

export default vips;
