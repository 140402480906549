import Vue from "vue";
import VueRouter from "vue-router";
import store from "../stores/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/sign-in",
    name: "sign_in",
    component: () =>
      import(/* webpackChunkName: "UserSignIn" */ "../views/UserSignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "sign_up",
    component: () =>
      import(/* webpackChunkName: "UserSignUp" */ "../views/UserSignUp.vue"),
  },
  {
    path: "/user/",
    name: "user_profile",
    component: () =>
      import(/* webpackChunkName: "UserProfile" */ "../views/UserProfile.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/user/edit",
    name: "user_edit",
    component: () =>
      import(/* webpackChunkName: "UserEdit" */ "../views/UserEdit.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/user/blocked-users",
    name: "blocked_users",
    component: () =>
      import(
        /* webpackChunkName: "BlockedUsers" */ "../views/BlockedUsers.vue"
      ),
    beforeEnter: requireAuth,
  },
  {
    path: "/forgot-password/",
    name: "forgot_password",
    component: () =>
      import(
        /* webpackChunkName: "UserForgotPassword" */ "../views/UserForgotPassword.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  // {
  //   path: "/chats",
  //   name: "chats",
  //   component: () =>
  //     import(/* webpackChunkName: "Chats" */ "../views/Chats.vue"),
  //   beforeEnter: requireAuth,
  // },
  {
    path: "/posts",
    name: "posts",
    component: () =>
      import(/* webpackChunkName: "Posts" */ "../views/Posts.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/legal/",
    name: "legal",
    component: () =>
      import(/* webpackChunkName: "Legal" */ "../views/Legal.vue"),
  },
  {
    path: "/legal/:id",
    name: "legal_page",
    component: () =>
      import(/* webpackChunkName: "LegalPage" */ "../views/LegalPage.vue"),
  },
  {
    path: "/surveys",
    name: "surveys",
    component: () =>
      import(/* webpackChunkName: "Surveys" */ "../views/Surveys.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/cms_pages/:id",
    name: "cms_page",
    component: () =>
      import(/* webpackChunkName: "CmsPage" */ "../views/CmsPage.vue"),
  },
  {
    path: "/event",
    name: "events",
    component: () =>
      import(/* webpackChunkName: "Events" */ "../views/Events.vue"),
  },
  {
    path: "/event/:id",
    name: "event",
    component: () =>
      import(/* webpackChunkName: "Event" */ "../views/Event.vue"),
  },
  {
    path: "/organisations/",
    name: "organisations",
    component: () =>
      import(
        /* webpackChunkName: "Organisations" */ "../views/Organisations.vue"
      ),
  },
  {
    path: "/organisations/:id",
    name: "organisation",
    component: () =>
      import(
        /* webpackChunkName: "Organisation" */ "../views/Organisation.vue"
      ),
  },
  {
    path: "/contact/",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
  },
  {
    path: "/welcome/",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "Welcome" */ "../views/Welcome.vue"),
  },
  {
    path: "/terms/",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "Terms" */ "../views/Terms.vue"),
  },
  {
    path: "/app-terms/",
    name: "app_terms",
    component: () =>
      import(/* webpackChunkName: "TermsInApp" */ "../views/TermsInApp.vue"),
  },
  {
    path: "/app-privacy/",
    name: "app_privacy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyInApp" */ "../views/PrivacyInApp.vue"
      ),
  },
  {
    path: "/app-accessibility/",
    name: "app_accessibility",
    component: () =>
      import(
        /* webpackChunkName: "AccessibilityInApp" */ "../views/AccessibilityInApp.vue"
      ),
  },
  {
    path: "/app-search/",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "AppSearch" */ "../views/AppSearch.vue"),
  },
  {
    path: "/favourites",
    name: "favourites",
    component: () =>
      import(/* webpackChunkName: "Favourites" */ "../views/Favourites.vue"),
  },
  {
    path: "/vips",
    name: "vips",
    component: () => import(/* webpackChunkName: "Vips" */ "../views/Vips.vue"),
  },
  {
    path: "/infomation-and-advice",
    name: "infoandadvice",
    component: () =>
      import(
        /* webpackChunkName: "InfoAndAdvice" */ "../views/InfoAndAdvice.vue"
      ),
  },
  {
    path: "/opportunity",
    name: "opportunity_categories",
    component: () =>
      import(
        /* webpackChunkName: "OpportunityCategories" */ "../views/OpportunityCategories.vue"
      ),
  },
  {
    path: "/opportunity/listing",
    name: "opportunities",
    component: () =>
      import(
        /* webpackChunkName: "Opportunities" */ "../views/Opportunities.vue"
      ),
  },
  {
    path: "/opportunity/:id",
    name: "opportunity",
    component: () =>
      import(/* webpackChunkName: "Opportunity" */ "../views/Opportunity.vue"),
  },
  {
    path: "/budget-planner",
    name: "budget_planner",
    component: () =>
      import(
        /* webpackChunkName: "BudgetPlanner" */ "../views/BudgetPlanner.vue"
      ),
  },
  {
    path: "/account-deleted",
    name: "account_deleted",
    component: () =>
      import(
        /* webpackChunkName: "AccountDeleted" */ "../views/AccountDeleted.vue"
      ),
  },
  {
    path: "/connect-with-us/",
    name: "community",
    component: () =>
      import(/* webpackChunkName: "Community" */ "../views/Community.vue"),
  },
  {
    path: "/discounts-and-offers/",
    name: "offers_archive",
    component: () =>
      import(
        /* webpackChunkName: "OffersArchive" */ "../views/OffersArchive.vue"
      ),
  },
  {
    path: "/discounts-and-offers/:id",
    name: "offers_article",
    component: () =>
      import(
        /* webpackChunkName: "OffersArticle" */ "../views/OffersArticle.vue"
      ),
  },
  {
    path: "/news/",
    name: "latest_news_archive",
    component: () =>
      import(
        /* webpackChunkName: "LatestNewsArchive" */ "../views/LatestNewsArchive.vue"
      ),
  },
  {
    path: "/news/:id",
    name: "latest_news_article",
    component: () =>
      import(
        /* webpackChunkName: "LatestNewsArticle" */ "../views/LatestNewsArticle.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      setTimeout(function () {
        window.scroll(0, savedPosition.y);
      }, 50);
      return;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // if not routing to the welcome page and the user has not set the required data
  // localStorage.getItem("user_type") === null || localStorage.getItem("user_location") === null ||
  // localStorage.getItem("user_type") && localStorage.getItem("user_location") &&
  if (
    to.name !== "welcome" &&
    (localStorage.getItem("current_lang") === null ||
      localStorage.getItem("terms_accepted") === null)
  ) {
    next({ name: "welcome" });
  } else if (
    to.name === "welcome" &&
    localStorage.getItem("current_lang") &&
    localStorage.getItem("terms_accepted")
  ) {
    next({ name: "home" });
  } else {
    next();
  }
});

function requireAuth(to, from, next) {
  if (store.getters["user/isTokenSet"]) {
    next();
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.name },
    });
  }
}

export default router;
